






































































































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import {
  generateEmailAddressAndPhoneNumber,
  loadWidget,
} from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IUser, PayloadState } from '@/types/types';
const usersModule = namespace('users');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { email, numeric, required, max, min } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('email', {
  ...email,
  message: 'Field must be an email address',
});
extend('numeric', {
  ...numeric,
  message: 'Field must contain only numbers',
});
extend('max', {
  ...max,
  message: (field: any, params: any) => {
    return `${field} cannot be more than ${params.length} characters`;
  },
});

extend('min', {
  ...min,
  message: (field: any, params: any) => {
    return `${field} cannot be less than ${params.length} characters`;
  },
});

@Component({
  name: 'AddPaymentMethod',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @usersModule.Getter('getResetFormValues') resetFormValues!: IUser & {
    disableSubAccountLogIn?: boolean;
    senderId: string;
  };

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  subAccount: Pick<
    IUser & { disableSubAccountLogIn?: boolean; senderId: string },
    'name' | 'phone' | 'email' | 'disableSubAccountLogIn' | 'senderId'
  > = {
    name: '',
    phone: '',
    email: '',
    senderId: '',
    disableSubAccountLogIn: false,
  };

  @Watch('subAccount.disableSubAccountLogIn')
  onDisableSubAccountLogInChanged(value: boolean): void {
    if (value) {
      const { email, phoneNumber } = generateEmailAddressAndPhoneNumber(22);
      this.subAccount.email = email?.toLocaleLowerCase();
      this.subAccount.phone = phoneNumber;
    } else {
      this.subAccount.email = '';
      this.subAccount.phone = '';
    }
  }

  @Watch('resetFormState')
  onFormChange(payload: boolean): void {
    if (payload) {
      this.subAccount = {
        ...this.resetFormValues,
        disableSubAccountLogIn: false,
      };
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Emit('subAccount')
  addSubAccount(): Pick<
    IUser & { disableSubAccountLogIn?: boolean; senderId: string },
    'name' | 'phone' | 'email' | 'disableSubAccountLogIn' | 'senderId'
  > {
    return this.subAccount;
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'add',
      state: false,
    };
  }
}
